/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2019-10-29
 */

import moment from "moment"
import { message } from "antd"
import _ from "lodash"
import constants from "@/constants"
import styles from "../components/XMLEditor/index.less"

export function deepCopy(obj) {
    return JSON.parse(JSON.stringify(obj))
    // if (obj === null || typeof obj !== 'object') {
    //     return obj
    // }
    // const copy = Array.isArray(obj) ? [] : {}
    // Object.keys(obj).forEach((key) => {
    //     copy[key] = deepCopy(obj[key])
    // })
    // return copy
}

const helper = {
    /**
     * 格式化模版, 例如:
     * format('hello, {name}', {name:'tom'}) 返回: hello, tom
     * @param str 字符串模版
     * @param obj 模版变量对应的对象
     */
    format: (str, obj) => {
        if (!obj) {
            return str
        }
        return str.replace(/\{([^}]+)\}/g, (_match, key) => obj[key])
    },
    sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms))
    },
    getRandomInt(min = 1, max = 100) {
        return Math.floor(Math.random() * max + min)
    },
    formatTime(start, format = "mm:ss", flag = true) {
        if (start) {
            let h = 0
            let m = 0
            let n = Math.floor(start / 1000)
            if (n >= 360) {
                h = Math.floor(n / 3600)
                n = Math.floor(n % 3600)
            }
            if (n >= 60) {
                m = Math.floor(n / 60)
                n = Math.floor(n % 60)
            }
            if (format === "mm:ss") {
                if (h && flag) {
                    return `${_.padStart(`${h}`, 2, "0")}:${_.padStart(`${m}`, 2, "0")}:${_.padStart(`${n}`, 2, "0")}`
                } else {
                    return `${_.padStart(`${m}`, 2, "0")}:${_.padStart(`${n}`, 2, "0")}`
                }
            } else if (format === "hh:mm:ss") {
                return `${_.padStart(`${h}`, 2, "0")}:${_.padStart(`${m}`, 2, "0")}:${_.padStart(`${n}`, 2, "0")}`
            }
        } else {
            return format === "mm:ss" ? "00:00" : "00:00:00"
        }
    },
    formatDuration(duration) {
        if (duration > 3600000) {
            return `${(duration / 3600000).toFixed(2)}小时`
        } else if (duration > 60000) {
            return `${Math.ceil(duration / 60000)}分钟`
        } else if (duration) {
            return `${duration / 1000}秒`
        } else {
            return "0"
        }
    },
    formatDate(timestamp) {
        if (timestamp) {
            timestamp = timestamp > 10000000000 ? timestamp : timestamp * 1000
            return moment(timestamp).format("ll")
        } else {
            return timestamp || "--"
        }
    },
    // 格式化时间为早上4点开始
    formatDateStart(timestamp) {
        if (timestamp) {
            let _time = moment(timestamp * 1000).format("YYYY-MM-DD")
            _time = `${_time} 04:00:00`
            return moment(_time).unix()
        } else {
            return timestamp
        }
    },
    // 格式化时间为第二天早上3：59分结束
    formatDateEnd(timestamp, hour = "03", add = 1) {
        if (timestamp) {
            let _time = moment(timestamp * 1000)
                .add(add, "days")
                .format("YYYY-MM-DD")
            _time = `${_time} ${hour}:59:59`
            return moment(_time).unix()
        } else {
            return timestamp
        }
    },
    formatTimestamp(timestamp, format = "YYYY-MM-DD") {
        if (timestamp) {
            timestamp = timestamp > 10000000000 ? timestamp : timestamp * 1000
            return moment(timestamp).format(format)
        }
        return "--"
    },
    formatDataID(timestamp, format = "MMDD") {
        if (timestamp) {
            timestamp = timestamp > 10000000000 ? timestamp : timestamp * 1000
            return moment(timestamp).format(format)
        }
        return "--"
    },
    formatDateTime(timestamp, format = "YYYY-MM-DD H:mm:ss") {
        if (timestamp) {
            timestamp = timestamp > 10000000000 ? timestamp : timestamp * 1000
            return moment(timestamp).format(format) // LLL
        }
        return "--"
    },
    formatDateMin(timestamp, format = "MM-DD H:mm") {
        if (timestamp) {
            timestamp = timestamp > 10000000000 ? timestamp : timestamp * 1000
            return moment(timestamp).format(format) // LLL
        }
        return "--"
    },
    addMonthTime(timestamp, month) {
        let newTimestamp = timestamp
        if (month) {
            const momentObj = moment.unix(timestamp)
            // 在moment对象上加月
            const newMomentObj = momentObj.add(month, "months")
            // 获取更新后的时间戳
            newTimestamp = newMomentObj.unix()
        }
        return helper.formatTimestamp(newTimestamp)
    },
    formatNumber(number, digits = 0) {
        if (typeof number === "number") {
            const base = 1e4
            const units = ["", "万", "亿"]
            let exponent = 0
            if (number < base) {
                return number
            }
            exponent = Math.floor(Math.log(number) / Math.log(base))
            return `${(number / Math.pow(base, exponent)).toFixed(digits)}${units[exponent]}`
        }
        return 0
    },
    getViewUrl(vid) {
        return `${constants.webHost}/videos/${vid}`
    },
    setLocalStorage: function (key, value) {
        const val = typeof value === "string" ? value : JSON.stringify(value)
        window.localStorage.setItem(key, val)
    },
    getLocalStorage: function (key) {
        let value = window.localStorage.getItem(key)
        if (value === undefined) {
            return null
        }
        return value
    },
    removeLocalStorage: function (key) {
        window.localStorage.removeItem(key)
    },
    parseParams: (querystring) => {
        const params = new URLSearchParams(querystring || window.location.search.substring(1))
        const obj = {}
        // iterate over all keys
        for (const key of params.keys()) {
            if (params.getAll(key).length > 1) {
                obj[key] = params.getAll(key)
            } else {
                obj[key] = params.get(key)
            }
        }
        return obj
    },
    validXml: (xmlString = "") => {
        let _temp = xmlString // xmlString.replace(/&/g, '')
        _temp = _temp.replace(/&nbsp;/g, " ")
        if (_temp && _temp.trim()) {
            _temp = `<div>${_temp}</div>`
            const domParser = new DOMParser()
            const dom = domParser.parseFromString(_temp, "text/xml")
            return !dom.documentElement.querySelector("parsererror")
        } else {
            return true
        }
    },
    checkTags: (xmlString, tags) => {
        let _checkDom = document.createElement("div")
        _checkDom.innerHTML = xmlString
        for (let _tag of tags) {
            if (_tag.toLowerCase() === "input") {
                let ipt = _checkDom.querySelectorAll("input")
                if (!ipt.length) {
                    return "缺少input标签"
                }
                for (let i = 0; i < ipt.length; i++) {
                    let _item = ipt[i]
                    if (!_item.getAttribute("value").trim()) {
                        return "input标签value非空"
                    }
                }
            }
        }
        return true
    },
    questionEqualsAnswer: (question, answer) => {
        const re = /<input/g
        const res = question.match(re)
        return res.length === answer.trim()?.split("\n")?.length
    },
    questionEqualsSubQuestion: (question, subQuestions) => {
        const re = /<q value="\d+" \/>/g
        const res = question.match(re)
        if (!res) {
            return false
        }
        for (let i = 0; i < res.length; i++) {
            let num = res[i].match(/\d+/g)
            if (+num[0] >= subQuestions.length) {
                return false
            }
        }
        return res.length === subQuestions.length
    },

    getSubjectId: () => {
        return +(window.localStorage.getItem("subject_id") || 0)
    },
    getLastCategoryId: () => {
        let _sid = +(window.localStorage.getItem("subject_id") || 0)
        let _key = window.localStorage.getItem(`last_category_id_${_sid}`)
        return _key ? +_key : ""
    },
    setLastCategoryId: (val) => {
        if (val !== undefined) {
            let _sid = +(window.localStorage.getItem("subject_id") || 0)
            window.localStorage.setItem(`last_category_id_${_sid}`, val)
        }
        return val
    },
    getLastKnowledgeCategory: () => {
        let _sid = +(window.localStorage.getItem("subject_id") || 0)
        let _key = window.localStorage.getItem(`last_knowledge_category_${_sid}`) || "{}"
        return JSON.parse(_key)
    },

    setLastKnowledgeCategory: (val, subId = 0) => {
        if (val !== undefined) {
            let _sid = subId || +(window.localStorage.getItem("subject_id") || 0)
            window.localStorage.setItem(`last_knowledge_category_${_sid}`, JSON.stringify(val))
        }
        return val
    },
    getLastQuestionRelationTypeID: () => {
        let _sid = +(window.localStorage.getItem("subject_id") || 0)
        return window.localStorage.getItem(`last_question_relation_type_id_${_sid}`) || ""
    },
    setLastQuestionRelationTypeID: (val, subId = 0) => {
        if (val !== undefined) {
            let _sid = subId || +(window.localStorage.getItem("subject_id") || 0)
            window.localStorage.setItem(`last_question_relation_type_id_${_sid}`, val)
        }
        return val
    },
    getLastKnowledgeTestTypeID: (_type = 0) => {
        let _sid = +(window.localStorage.getItem("subject_id") || 0)
        return window.localStorage.getItem(`last_knowledge_text_type_id_${_sid}_${_type}`) || ""
    },
    setLastKnowledgeTestTypeID: (val, subId = 0, _type = 0) => {
        if (val !== undefined) {
            let _sid = subId || +(window.localStorage.getItem("subject_id") || 0)
            window.localStorage.setItem(`last_knowledge_text_type_id_${_sid}_${_type}`, val)
        }
        return val
    },
    getLastOutlineCategory: (rootId = "") => {
        let _sid = +(window.localStorage.getItem("subject_id") || 0)
        let _key = window.localStorage.getItem(`last_outline_category_${_sid}_${rootId}`) || "{}"
        return JSON.parse(_key)
    },
    setLastOutlineCategory: (val, subId = 0, rootId = "") => {
        if (val !== undefined) {
            let _sid = subId || +(window.localStorage.getItem("subject_id") || 0)
            window.localStorage.setItem(`last_outline_category_${_sid}_${rootId}`, JSON.stringify(val))
        }
        return val
    },
    getLastSkillCategory: () => {
        let _sid = +(window.localStorage.getItem("subject_id") || 0)
        let _key = window.localStorage.getItem(`last_skill_category_${_sid}`) || "{}"
        return JSON.parse(_key)
    },
    setLastSkillCategory: (val) => {
        if (val !== undefined) {
            let _sid = +(window.localStorage.getItem("subject_id") || 0)
            window.localStorage.setItem(`last_skill_category_${_sid}`, JSON.stringify(val))
        }
        return val
    },
    getLastLevelCategory: () => {
        let _sid = +(window.localStorage.getItem("subject_id") || 0)
        let _key = window.localStorage.getItem(`last_level_category_${_sid}`) || "{}"
        return JSON.parse(_key)
    },
    setLastLevelCategory: (val) => {
        if (val !== undefined) {
            let _sid = +(window.localStorage.getItem("subject_id") || 0)
            window.localStorage.setItem(`last_level_category_${_sid}`, JSON.stringify(val))
        }
        return val
    },
    html2Text: (str) => {
        const d = document.createElement("div")
        d.innerHTML = str
        return d.innerText
    },
    htmlChild2Text: (str) => {
        const d = document.createElement("div")
        d.innerHTML = str
        let txt = ""
        d.childNodes.forEach((n) => {
            if (n.tagName === "INPUT") {
                if (n.getAttribute("value")) {
                    txt = txt + n.getAttribute("value")
                }
            } else {
                txt = txt + n.textContent
            }
        })
        return txt
    },
    getDomByPath: (_str, _path) => {
        const d = document.createElement("div")
        d.innerHTML = _str
        return d.querySelectorAll(_path)
    },
    // 过滤标点符号

    removeArrSym: (arr) => {
        const removeSym = (str) => {
            const sym = ["。", "，", "！", "、", "；", ".", "：", ",", "!", ";", ":", "？", "?"]
            let _str = ""
            for (let i = 0; i < str.length; i++) {
                if (!sym.includes(str[i])) {
                    _str += str[i]
                }
            }
            // console.log('old', str)
            // sym.forEach((s) => {
            //     // console.log('replace s=====', s)
            //     _str = _str.replace(new RegExp(s, 'g'), '')
            //     console.log(str, '====new old=====', _str)
            // })
            return _str
        }
        let _res = []
        arr.forEach((_str) => {
            let _nStr = removeSym(_str)

            if (_nStr.trim()) {
                _res.push(_nStr)
            }
        })
        return _res
    },
    getAnswerStr: (item) => {
        let res = []
        let keys = item.answer.split(constants.splitSyn)
        keys.forEach((key) => {
            res.push(`${constants.letter[key]}、${item.options[key]}`)
        })
        return res.join("；")
    },
    isChinese(str) {
        const re = /[^\u4e00-\u9fa5]/
        if (re.test(str)) {
            return false
        }
        return true
    },
    isEnglish(str) {
        const pa = new RegExp("[A-Za-z]+")
        return pa.test(str)
    },
    getText: (_str) => {
        let _res = ""
        const _div = document.createElement("div")
        _div.innerHTML = _str
        _res = _div.innerText

        _div.querySelectorAll("input").forEach((_ipt) => {
            if (_ipt.getAttribute("value")) {
                _res = `${_res} ${_ipt.getAttribute("value")}`
            }
        })
        return _res
    },
    getAnswers: (_str) => {
        let _res = []
        const _div = document.createElement("div")
        _div.innerHTML = _str
        _div.querySelectorAll("input").forEach((_ipt) => {
            _res.push(_ipt.getAttribute("value") || "")
        })
        return _res
    },
    formatLatex(str, subQuestions) {
        let _val = str.replace(/\n/g, "<br />")
        _val = _val.replace(/▷/g, ">")
        _val = _val.replace(/◁/g, "<")
        //
        // let _s = document.createElement('div')
        // _s.innerHTML = str
        // _s.childNodes.forEach((_d) => {
        //     if (_d.tagName === 'LATEX') {
        //         if (_d.innerText.indexOf('\\\\') !== -1) {
        //             _d.replaceWith(`<latex2>${_d.innerHTML}</latex2>`)
        //         }
        //     }
        // })

        // _val = decodeURIComponent(_s.innerHTML)
        // console.log('latex==', _val)
        // _val = _val.replace(/<latex>/g, '$')
        // _val = _val.replace(/<\/latex>/g, '$')

        // _val = _val.replace(/<latex2>/g, '$$')
        // _val = _val.replace(/<\/latex2>/g, '$$')

        const div = document.createElement("div")
        div.innerHTML = _val
        div.querySelectorAll("input").forEach((ipt, idx) => {
            if (ipt.getAttribute("type") === "latex") {
                const span = document.createElement("span")
                span.className = `${styles.latexIpt} latexIpt`
                if (ipt.getAttribute("value")) {
                    span.innerText = `$${ipt.getAttribute("value")}$`
                }
                ipt.replaceWith(span)
            }
            if (ipt.getAttribute("value")) {
                let _val = +ipt.getAttribute("value")
                let _qid = ipt.getAttribute("qid")
                let _newTip = ""
                if (typeof _val === "number" && _val % 1 === 0) {
                    if (constants.letter[_val]) {
                        _newTip = `${constants.letter[_val]}`
                        if (_qid) {
                            _newTip = `${_qid}：${_newTip}`
                        }
                    }
                }
                if (!_newTip) {
                    _newTip = ipt.getAttribute("value")
                    if (_qid) {
                        _newTip = `${_qid}：${_newTip}`
                    }
                }
                ipt.setAttribute("value", _newTip)
            } else {
                // 完形填空无value情况从小题中取
                if (subQuestions.length) {
                    let _qid = ipt.getAttribute("qid")
                    let _str = subQuestions[idx]["options"][subQuestions[idx]["answer"]]
                    if (_str) {
                        _str = `${_qid}：${_str}`
                    }
                    ipt.setAttribute("value", _str)
                }
            }
        })
        div.querySelectorAll("latex").forEach((l) => {
            if (l.innerText.indexOf("\\\\") !== -1) {
                l.textContent = `$$${l.textContent}$$`
            } else {
                l.textContent = `$${l.textContent}$`
            }
        })
        return div.innerHTML
    },
    cleanInput(str) {
        const reg = /<input\s+value\s?=\s?"(.*?)"\s+\/>/g
        return str.replace(reg, function (x) {
            const div = document.createElement("div")
            x = x.replace(/\+/g, "、")
            div.innerHTML = x
            const ipt = div.querySelector("input")
            return ipt.value.trim()
        })
    },
    //全角转换为半角函数
    toCDB(str) {
        let tmp = ""
        const nums = ["０", "１", "２", "３", "４", "５", "６", "７", "８", "９", "．", "－", "＋", "："]
        const fnums = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ".", "-", "+", ":"]
        for (let i = 0; i < str.length; i++) {
            let _idx = nums.findIndex((s) => s === str[i])
            if (_idx !== -1) {
                tmp += fnums[_idx]
            } else {
                tmp += str[i]
            }
        }

        return tmp
    },
    formatVideoTime(seconds) {
        let _seconds = parseInt(seconds / 1000)
        let minutes = Math.floor(_seconds / 60)
        minutes = minutes >= 10 ? minutes : "0" + minutes
        _seconds = Math.floor(_seconds % 60)
        _seconds = _seconds >= 10 ? _seconds : "0" + _seconds
        return minutes + ":" + _seconds
    },
    getPromptKV(_cc) {
        const _prompt = {}
        const regex = /<([^<>]+)>/g
        const matches = _cc.match(regex)
        if (matches && matches.length) {
            const extractedContent = matches.map((match) => match.slice(1, -1))
            extractedContent.forEach((_key) => {
                if (!["submission", "question"].includes(_key)) {
                    _prompt[_key] = ""
                }
            })
        }
        return _prompt
    },
    removeImgTag(text) {
        return text.replace(/<img (.*?) \/>/g, "")
    },
    removeInputTag(text) {
        return text.replace(/<input value="(.*?)" \/>/g, "$1")
    },
    clearHint(str) {
        // let str = 'There is【有】 a big[大的] playground(操场) in the middle of the school(学校).';
        if (str) {
            str = helper.removeImgTag(str)
            str = helper.removeInputTag(str)
            str = str
                .replace(/【.*?】/g, " ")
                .replace(/（.*?）/g, " ")
                .replace(/\[[^)]*\]/g, " ")
                .replace(/\([^)]*\)/g, " ")
                .replace(/  +/g, " ")
        }
        return str
        // console.log(str)
    },

    clearTplHint(obj) {
        let res = {}
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                res[key] = helper.clearHint(obj[key])
            }
        }
        return res
    },
    removeAllTag(str) {
        if (str) {
            str = helper.removeInputTag(str)
            let d = document.createElement("div")
            d.innerHTML = str
            return d.innerText
        }
        return str
    },
    htmlToSentence(str) {
        if (str) {
            str = helper.removeInputTag(str)
            str = helper.clearHint(str)
            const d = document.createElement("div")
            d.innerHTML = str
            // const e = document.createElement('div')
            // e.innerHTML = d.innerText
            str = d.innerText
            // str = str.replace(/[^a-zA-Z,.?!;:'"\s-\d]/g, '') // 过滤掉非英文及标点以外的所有字符
            str = str.replace(/\(.*?\)/g, (match) => {
                // 判断括号内是否包含中文
                if (/[^\x00-\xff]/.test(match)) {
                    return "" // 如果包含中文，则返回空字符串
                } else {
                    return match // 如果不包含中文，则返回原字符串
                }
            })
            return str
        }
        return str
    },
    hasInvalidCharacters(str) {
        // 正则表达式匹配英文字母、“-”和“ '“ ’’‘‛＇
        const validRegex = /^[A-Za-z \-'’‘‛＇`，. ,.…...⋯᠁]+$/
        return !validRegex.test(str)
    },
    replaceTextStrong(str, words) {
        let _text = str
        let idxOffset = 0
        words.forEach((item) => {
            let idx = item.i + idxOffset
            const _newText = `*${item["w"]}*`
            _text = `${_text.substring(0, idx)}${_newText}${_text.substring(idx + item["w"].length)}`
            idxOffset = idxOffset + _newText.length - item["w"].length
        })
        // console.log('old text:', str)
        // console.log('new text:', _text)
        return _text
    },
    // 单词排版
    replaceTextSpan(str, words) {
        let _text = str
        let idxOffset = 0
        words.forEach((item) => {
            let idx = item.i + idxOffset
            const _newText = `<span isWord data-l="${item["l"] || ""}" data-w="${item["w"] || ""}">${item["w"]}</span>`
            _text = `${_text.substring(0, idx)}${_newText}${_text.substring(idx + item["w"].length)}`
            idxOffset = idxOffset + _newText.length - item["w"].length
        })
        // console.log('old text:', str)
        // console.log('new text:', _text)
        return _text
    },
    checkReg(_reArr, _str) {
        // console.log('re====', _reArr, _str)
        let res = []
        for (let i = 0; i < _reArr.length; i++) {
            let _arr = _reArr[i].split(">>")
            if (_arr[0]) {
                let _r = new RegExp(_arr[0], "ig")
                const _t = _r.test(_str)
                if (!_t) {
                    console.log("rrr", _str, _r, _t, _arr[0])
                    res.push(`【${_str}】 ${_arr[1] || "正则匹配失败!"}：${_arr[0]}`)
                }
            }
        }
        return res.join("\n")
    },
    checkExplain(obj, regObj, ignoreWord, ignorePhrase) {
        if (!Array.isArray(obj.grammars)) {
            return "语法内容格式错误！"
        }
        if (!Array.isArray(obj.words)) {
            return "单词内容格式错误！"
        }
        if (!Array.isArray(obj.phrases)) {
            return "词组或短语内容格式错误！"
        }
        for (let i = 0; i < obj.grammars.length; i++) {
            let _item = obj.grammars[i]
            if (!_item.hasOwnProperty("content")) {
                return "语法中未包含 content 属性"
            }
            if (!_item.hasOwnProperty("element")) {
                return "语法中未包含 element 属性"
            }
            if (!_item["content"]) {
                return "语法中存在content为空或null"
            }
            if (!_item["element"]) {
                return "语法中存在element为空或null"
            }
            let _e = _item["element"].replace("-", " ")
            if (!constants.grammarElements.includes(_e.toLowerCase())) {
                return `语法中存在element非法：${_e}。${constants.grammarElements.join("\n")}`
            }
        }
        for (let i = 0; i < obj.words.length; i++) {
            let _item = obj.words[i]
            if (!_item.hasOwnProperty("word")) {
                return "单词中未包含 word 属性"
            }
            if (!_item["word"]) {
                return "单词中存在word为空"
            }
            let _word = _item["word"]
            if (!_item.hasOwnProperty("translation")) {
                return `【${_word}】单词中未包含 translation 属性`
            }
            // if (!_item.hasOwnProperty('explanation')) {
            //     return `【${_word}】单词中未包含explanation属性`
            // }
            if (!_item.hasOwnProperty("baseform")) {
                return `【${_word}】单词中未包含 baseform 属性`
            }
            if (!_item.hasOwnProperty("sentence_cn")) {
                return `【${_word}】单词中未包含 sentence_cn 属性`
            }
            if (!_item.hasOwnProperty("sentence_en")) {
                return `【${_word}】单词中未包含 sentence_en 属性`
            }

            if (_item["sentence_en"].indexOf("[[") === -1) {
                return `【${_word}】sentence_en 中未找到强调单词！`
            }
            if (_item["word"].length === 1 && !["a", "I"].includes(_item["word"])) {
                return "单词中word为一个字符!"
            }
            // if (helper.hasInvalidCharacters(_item['word'])) {
            //     return "word字段包含除了英文字母及- 和 ’‘‛＇`，. ,.…...⋯᠁ 之外的标点符号"
            // }
            let res = ""
            if (regObj.word.word.length && !ignoreWord) {
                res = helper.checkReg(regObj.word.word, _item["word"])
                if (res) {
                    return res
                }
            }
            if (!_item["translation"]) {
                return "单词中存在translation为空"
            }
            // if (_item['translation'].indexOf('，') !== -1 || _item['translation'].indexOf('；') !== -1 || _item['translation'].indexOf(',') !== -1 || _item['translation'].indexOf(';') !== -1) {
            //     return 'translation 字段带 , ; (中英文逗号，分号）'
            // }
            // if (_item['translation'].length > 12) {
            //     return 'translation 字段长度大于 12 （说明把提示词直接放进去了）'
            // }
            if (regObj.word.translation.length && !ignoreWord) {
                res = helper.checkReg(regObj.word.translation, _item["translation"])
                if (res) {
                    return res
                }
            }

            if (!_item["explanation"]) {
                return "单词中存在explanation为空或null"
            }
            if (regObj.word.explanation.length && !ignoreWord) {
                res = helper.checkReg(regObj.word.explanation, _item["explanation"])
                if (res) {
                    return res
                }
            }
            if (!_item["baseform"]) {
                return "单词中存在baseform为空或null"
            }
            if (regObj.word.baseform.length && !ignoreWord) {
                res = helper.checkReg(regObj.word.baseform, _item["baseform"])
                if (res) {
                    return res
                }
            }
        }
        for (let i = 0; i < obj.phrases.length; i++) {
            let _item = obj.phrases[i]
            if (!_item.hasOwnProperty("phrase")) {
                return "词组或短语中未包含 phrase 属性"
            }
            if (_item["phrase"].indexOf(" ") === -1 && _item["phrase"].indexOf("-") === -1 && _item["phrase"].indexOf(",") === -1 && _item["phrase"].indexOf("，") === -1) {
                return "phrase 空格和 - 与 ， 必须有一个，否则说明不是词组"
            }
            const _phrase = _item["phrase"]

            if (!_item.hasOwnProperty("translation")) {
                return `【${_phrase}】词组或短语中未包含 translation 属性`
            }
            if (!_item.hasOwnProperty("sentence_cn")) {
                return `【${_phrase}】词组或短语中未包含 sentence_cn 属性`
            }
            if (!_item.hasOwnProperty("sentence_en")) {
                return `【${_phrase}】词组或短语中未包含 sentence_en 属性`
            }
            if (_item["sentence_en"].indexOf("[[") === -1) {
                return `【${_phrase}】sentence_en 中未找到强调单词！`
            }
            if (!_item["phrase"]) {
                return `【${_phrase}】词组或短语中存在phrase为空或null`
            }

            let res = ""
            if (regObj.phrase.phrase.length && !ignorePhrase) {
                res = helper.checkReg(regObj.phrase.phrase, _item["phrase"])
                if (res) {
                    return res
                }
            }
            if (!_item["translation"]) {
                return `【${_phrase}】词组或短语中存在translation为空或null`
            }

            if (regObj.phrase.translation.length && !ignorePhrase) {
                res = helper.checkReg(regObj.phrase.translation, _item["translation"])
                if (res) {
                    return res
                }
            }

            if (!_item["explanation"]) {
                return `【${_phrase}】词组或短语中存在explanation为空或null`
            }

            if (regObj.phrase.explanation.length && !ignorePhrase) {
                res = helper.checkReg(regObj.phrase.explanation, _item["explanation"])
                if (res) {
                    return res
                }
            }
        }
        return ""
    },
    clearSentence(_str) {
        if (_str) {
            const regex = /<sentence(.+?)>/g
            const regex1 = /<\/sentence>/g
            let result = _str.replace(regex, (match, content) => "")
            result = result.replace(regex1, (match, content) => "")
            return result
        }
        return _str
    },
    /*
    params:{key:value} key in ['sentence_en', 'sentence_cn', 'sentences', 'question', 'options', 'answer', 'passage']
    */
    tplToMessage(tpl, params) {
        let _tpl = tpl
        constants.tplKeys.forEach((_key) => {
            if (params.hasOwnProperty(_key) && params[_key]) {
                let reg = new RegExp(`{${_key}}`, "g")
                _tpl = _tpl.replace(reg, params[_key])
            }
        })
        return _tpl
    },
    arrToKV(arr = []) {
        let kv = {}
        arr.forEach((_item) => {
            kv[_item.id] = _item.name
        })
        return kv
    },
    arrToOptions(arr, all = true) {
        return arr.map((_item) => ({
            label: _item.name,
            value: _item.id,
        }))
        // if (all) {
        //     return [{ 'label': '全部', 'value': 0 }].concat(arr.map((_item) => ({
        //         label: _item.name,
        //         value: _item.id
        //     })))
        // } else {
        //     return arr.map((_item) => ({
        //         label: _item.name,
        //         value: _item.id
        //     }))
        // }
    },
    clearJSON(_str, _arr) {
        if (_str) {
            _str = _str.replace("```json", "")
            _str = _str.replace("```", "")
            try {
                _arr.forEach((_re) => {
                    _str = _str.replace(new RegExp(_re.trim(), "g"), "")
                })
            } catch (e) {
                message.error("正则替换失败，打开控制台查看具体错误信息！")
                console.log("json内容：", _str)
                console.log("正则内容：", _arr)
                console.error("错误提示", e)
            }
        }
        return _str
    },
    objectOrder(parsedArr, keyOrder) {
        let res = []
        parsedArr.forEach((_item) => {
            let orderedObject = {}
            keyOrder.forEach((key) => {
                orderedObject[key] = _item[key]
            })
            res.push(orderedObject)
        })
        return res
    },
    formatQuestionGptText(question, index) {
        let dom = document.createElement("div")
        const _subQuestion = question.sub_questions[index]
        dom.innerHTML = question.title
        const _titleText = helper.clearHint(dom.innerText)
        dom.innerHTML = _subQuestion.title
        let _questionText = helper.clearHint(dom.innerText)
        _questionText = _questionText.replace(/\n/g, " ")
        const _options = []
        _subQuestion.options.forEach((_text, _idx) => {
            _text = _text.replace(/\n/g, " ")
            _options.push(helper.clearHint(`${constants.letter[_idx]}、${_text}`))
        })
        const _optionsText = _options.join("\n")
        // 题目是选择题
        let _answer = ""
        if ([1].includes(_subQuestion.question_type_id)) {
            let _idx = +_subQuestion.answer
            _answer = `${constants.letter[_idx]}、${_subQuestion.options[_idx]}`
        } else {
            _answer = _subQuestion.answer
        }
        const _answerText = helper.clearHint(_answer)
        return {
            titleText: _titleText,
            questionText: _questionText,
            optionsText: _optionsText,
            answer: _answerText,
            gptText: `passage:${_titleText}\nquestion:${_questionText}\noptions:${_optionsText}\nanswer:${_answerText}`,
        }
    },
    isEnglishLetter: (char) => {
        return /^[A-Za-z]+$/.test(char)
    },
    countChars(str, char) {
        let count = 0
        for (let i = 0; i < str.length; i++) {
            if (str.charAt(i) === char) {
                count++
            }
        }
        return count
    },
    containsChinese(str) {
        // 正则表达式用于匹配一个或多个中文字符
        const pattern = /[\u4e00-\u9fff]/
        return pattern.test(str)
    },
    clearGPTQuestionStr(str) {
        let _str = helper.clearHint(helper.html2Text(str))
        if (_str < 3) {
            return ""
        } else if (helper.containsChinese(_str)) {
            // 过滤中文
            return ""
        } else {
            return _str
        }
    },
    fixStrong(text, substr, position = 0) {
        let reg = new RegExp(`${substr}`, "gm")
        let count = -1
        let fix = false
        let posIndex = -1
        let res = text.replace(reg, function (matched, index) {
            if (index >= 0) {
                // 有匹配到不包含中文的需要判断前后是否英文分割符
                if (!helper.containsChinese(substr)) {
                    let _preLetter = text[index - 1]
                    // 去掉sentence标签及id=",class="
                    // 下标为  判断前面一个字符合法
                    if (index !== 0 && _preLetter && (helper.isEnglishLetter(_preLetter) || _preLetter === "<")) {
                        // console.log("111Count：", count,'Substr：', substr, 'pre:',_preLetter, 'isEn:',helper.isEnglishLetter(_preLetter))
                        return matched
                    }
                    let _nextLetter = text[index + substr.length] || ""
                    let _next2Letter = text[index + substr.length + 1] || ""
                    // 去掉sentence标签及id=",class="
                    // 判断 后面一个字符
                    if (_nextLetter && (helper.isEnglishLetter(_nextLetter) || (_nextLetter === "=" && _next2Letter === '"'))) {
                        return matched
                    }
                }
                count++ // 计数从0开始
                if (count === position) {
                    fix = true
                    posIndex = index
                    return `[[${substr}]]`
                } else {
                    return matched
                }
            } else {
                return matched
            }
        })
        return { text: res, fix: fix, index: posIndex }
    },
    getExplainIdByString(_str) {
        if (_str) {
            let _dom = document.createElement("div")
            _dom.innerHTML = _str
            let _sen = _dom.querySelector("sentence")
            if (_sen && _sen.getAttribute("id")) {
                return +(_sen.getAttribute("id") || 0)
            }
        } else {
            return 0
        }
    },
    nlp_replace_space(_str) {
        const placeholderSym = "♨"
        const regexBr = /<(.+?)\/>/g
        _str = _str.replace(regexBr, (match, content) => placeholderSym + placeholderSym.repeat(content.length) + placeholderSym)
        const regex = /<(.+?)>/g
        _str = _str.replace(regex, (match, content) => placeholderSym + placeholderSym.repeat(content.length) + placeholderSym)
        const regex1 = /\((.+?)\)/g
        _str = _str.replace(regex1, (match, content) => placeholderSym + placeholderSym.repeat(content.length) + placeholderSym)
        _str = _str.replace(/[^\x00-\xff]/g, placeholderSym)
        return _str
    },
    formatTranslation(val) {
        let words = {}
        let arr = val.split("\n")
        arr.forEach((_s) => {
            let _a = _s.split("|")
            if (_a.length === 2) {
                words[_a[0].trim()] = _a[1].trim()
            }
        })
        return words
    },
}
export default helper
