/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2020/9/4
 */
import { lazy } from 'react'
import BaseLayout from './layouts/Base'
import DataLayout from './layouts/Data'

// pages
const Dashboard = lazy(() => import('./pages/dashboard'))
const Words = lazy(() => import('./pages/words'))
const ChineseWords = lazy(() => import('./pages/chineseWords'))
const Outlines = lazy(() => import('./pages/outlines'))
const OutlinesDetail = lazy(() => import('./pages/outlines/detail'))
const OutlinesLevel = lazy(() => import('./pages/outlines/level'))
const Exercises = lazy(() => import('./pages/exercises'))
const ExercisesDetail = lazy(() => import('./pages/exercises/detail'))
const ExercisesArticle = lazy(() => import('./pages/exercises/article'))
const Grammar = lazy(() => import('./pages/grammar'))
const Knowledge = lazy(() => import('./pages/knowledge'))
const KnowledgeEdit = lazy(() => import('./pages/knowledge/edit'))
const Questions = lazy(() => import('./pages/questions'))
const QuestionsEdit = lazy(() => import('./pages/questions/put'))
const QuestionTypes = lazy(() => import('./pages/questionTypes'))
const SkillFeedback = lazy(() => import('./pages/feedback/skill'))
const KnowledgeFeedback = lazy(() => import('./pages/feedback/knowledge'))
const UserKnowledge = lazy(() => import('./pages/data/userKnowledge'))
const UserQuestions = lazy(() => import('./pages/data/userQuestions'))
const Users = lazy(() => import('./pages/users'))
const Skill = lazy(() => import('./pages/skill'))
const Levels = lazy(() => import('./pages/levels'))
const StatsRealTime = lazy(() => import('./pages/stats/realtime'))
const StatsUsers = lazy(() => import('./pages/stats/users'))
const StatsTasks = lazy(() => import('./pages/stats/tasks'))
const StatsKnowledge = lazy(() => import('./pages/stats/knowledge'))
const StatsTasksDetail = lazy(() => import('./pages/stats/tasks/detail'))
const StatsUserDetail = lazy(() => import('./pages/stats/users/detail'))
const StatsUserSubjectsDetail = lazy(() => import('./pages/stats/users/subject'))
const StatsUserSkillDetail = lazy(() => import('./pages/stats/users/detail/components/Skill/PageDetail'))
const Tasks = lazy(() => import('./pages/data/tasks'))
const LevelTasks = lazy(() => import('./pages/data/levelTasks'))
const ReviewTasks = lazy(() => import('./pages/data/reviewTasks'))
const Organizations = lazy(() => import('./pages/data/organizations'))
const SysConfig = lazy(() => import('./pages/sysConfig'))
const Papers = lazy(() => import('./pages/papers'))
const PromptTpl = lazy(() => import('./pages/promptTpl'))
const PapersPut = lazy(() => import('./pages/papers/put'))
const CoursePut = lazy(() => import('./pages/courses/put'))
const CourseDetailPage = lazy(() => import('./pages/courseDetailPage'))
const PassLevelPut = lazy(() => import('./pages/passLevels/put'))
const RepeatPut = lazy(() => import('./pages/repeats/put'))
const Setting = lazy(() => import('./pages/setting'))
const Template = lazy(() => import('./pages/template'))
const GptQuestionWords = lazy(() => import('./pages/gpt/questions/words'))
const SignIn = lazy(() => import('./pages/signIn'))
const SignOut = lazy(() => import('./pages/signOut'))
// const Map= lazy(() => import('./pages/demo/map'))
const NotFound = lazy(() => import('./pages/404'))

const router = [
    {
        path: '/dashboard',
        component: Dashboard,
        exact: true
    },
    {
        path: '/chinese_words',
        component: ChineseWords,
        exact: true,
        layout: BaseLayout
    },
    {
        path: '/words',
        component: Words,
        exact: true,
        layout: BaseLayout
    },
    {
        path: '/outlines',
        component: Outlines,
        exact: true,
        layout: BaseLayout
    },
    {
        path: '/outlines/:id',
        component: OutlinesDetail,
        exact: true,
        layout: BaseLayout
    },

    {
        path: '/common-outlines',
        component: Outlines,
        exact: true,
        layout: BaseLayout
    },
    {
        path: '/common-outlines/:id',
        component: OutlinesDetail,
        exact: true,
        layout: BaseLayout
    },
    {
        path: '/level-outlines',
        component: Outlines,
        exact: true,
        layout: BaseLayout
    },
    {
        path: '/level-outlines/:id',
        component: OutlinesLevel,
        exact: true,
        layout: BaseLayout
    },
    {
        path: '/exercises',
        component: Exercises,
        exact: true,
        layout: BaseLayout
    },
    {
        path: '/exercises/:id',
        component: ExercisesDetail,
        exact: true,
        layout: BaseLayout
    },
    {
        path: '/exercises/:id/article',
        component: ExercisesArticle,
        exact: true,
        layout: BaseLayout
    },
    {
        path: '/prompt-tpl',
        component: PromptTpl,
        exact: true,
        layout: BaseLayout
    },
    {
        path: '/grammar',
        component: Grammar,
        exact: true,
        layout: BaseLayout
    },
    {
        path: '/question_types',
        component: QuestionTypes,
        exact: true,
        layout: BaseLayout
    },
    {
        path: '/knowledge',
        component: Knowledge,
        exact: true,
        layout: BaseLayout
    },
    {
        path: '/knowledge/:id',
        component: KnowledgeEdit,
        exact: true,
        layout: BaseLayout
    },
    {
        path: '/skill',
        component: Skill,
        exact: true,
        layout: BaseLayout
    },
    {
        path: '/questions',
        component: Questions,
        exact: true,
        layout: BaseLayout
    },
    {
        path: '/questions/:id',
        component: QuestionsEdit,
        exact: true,
        layout: BaseLayout
    },
    {
        path: '/questions/:id/create',
        component: QuestionsEdit,
        exact: true,
        layout: BaseLayout
    },
    {
        path: '/courses/:id',
        component: CoursePut,
        exact: true,
        layout: BaseLayout
    },
    {
        path: '/courses/:id/detail',
        component: CourseDetailPage,
        exact: true
    },
    {
        path: '/levels/:id',
        component: PassLevelPut,
        exact: true,
        layout: BaseLayout
    },
    {
        path: '/repeats/:id',
        component: RepeatPut,
        exact: true,
        layout: BaseLayout
    },
    {
        path: '/english_listening',
        component: Levels,
        exact: true,
        layout: BaseLayout
    },
    {
        path: '/english_reading',
        component: Levels,
        exact: true,
        layout: BaseLayout
    },
    {
        path: '/papers',
        component: Papers,
        exact: true,
        layout: BaseLayout
    },
    {
        path: '/papers/:id',
        component: PapersPut,
        exact: true,
        layout: BaseLayout
    },
    {
        path: '/classical_chinese_reading',
        component: Levels,
        exact: true,
        layout: BaseLayout
    },
    {
        path: '/modern_chinese_reading',
        component: Levels,
        exact: true,
        layout: BaseLayout
    },
    {
        path: '/skill_feedback',
        component: SkillFeedback,
        exact: true,
        layout: DataLayout
    },
    {
        path: '/knowledge_feedback',
        component: KnowledgeFeedback,
        exact: true,
        layout: DataLayout
    },
    {
        path: '/user_knowledge',
        component: UserKnowledge,
        exact: true,
        layout: DataLayout
    },
    {
        path: '/user_questions',
        component: UserQuestions,
        exact: true,
        layout: DataLayout
    },
    {
        path: '/stats/tasks',
        component: StatsTasks,
        exact: true,
        layout: DataLayout
    },
    {
        path: '/stats/tasks/:id',
        component: StatsTasksDetail,
        exact: true,
        layout: DataLayout
    },
    {
        path: '/stats/knowledge',
        component: StatsKnowledge,
        exact: true,
        layout: DataLayout
    },
    {
        path: '/stats/knowledge/:id',
        component: StatsTasksDetail,
        exact: true,
        layout: DataLayout
    },

    {
        path: '/stats/users',
        component: StatsUsers,
        exact: true,
        layout: DataLayout
    },
    {
        path: '/stats/users/:id',
        component: StatsUserDetail,
        exact: true,
        layout: DataLayout
    },
    {
        path: '/template/:id',
        component: Template,
        exact: true
    },
    {
        path: '/stats/users/:id/subjects',
        component: StatsUserSubjectsDetail,
        exact: true,
        layout: DataLayout
    },
    {
        path: '/stats/users/:id/skill/:skill_id',
        component: StatsUserSkillDetail,
        exact: true,
        layout: DataLayout
    },
    {
        path: '/stats/realtime',
        component: StatsRealTime,
        exact: true,
        layout: DataLayout
    },
    {
        path: '/level_tasks',
        component: LevelTasks,
        exact: true,
        layout: DataLayout
    },
    {
        path: '/tasks',
        component: Tasks,
        exact: true,
        layout: DataLayout
    },
    {
        path: '/review_tasks',
        component: ReviewTasks,
        exact: true,
        layout: DataLayout
    },
    {
        path: '/users',
        component: Users,
        exact: true,
        layout: DataLayout
    },
    {
        path: '/sys_config',
        component: SysConfig,
        exact: true,
        layout: DataLayout
    },
    {
        path: '/organizations',
        component: Organizations,
        exact: true,
        layout: DataLayout
    },
    {
        path: '/gpt/questions/words/:qid',
        component: GptQuestionWords,
        exact: true
    },

    {
        path: '/setting',
        component: Setting,
        exact: true,
        layout: BaseLayout
    },
    {
        path: '/sign-in',
        component: SignIn,
        exact: true
    },
    {
        path: '/sign-out',
        component: SignOut,
        exact: true
    },
    // {
    //     path: '/demo/map',
    //     component: Map,
    //     exact: true
    // },
    {
        path: '*',
        component: NotFound
    }
]
export default router