/**
 * 文件说明:
 * ----------------------------------------
 * 创建用户: 1625 [zhanghedong@gmail.com]
 * 创建日期: 2020/9/4
 */

import { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Layout, Menu } from 'antd'
import { useDispatch } from 'react-redux'
import { setSubjects } from 'actions/base'
import api from 'api/constants'
import {
    HeatMapOutlined,
    LogoutOutlined,
    UserOutlined,
    ScheduleOutlined,
    SafetyOutlined,
    AreaChartOutlined,
    TeamOutlined,
    FundOutlined,
    CheckCircleOutlined,
    IssuesCloseOutlined,
    ClusterOutlined
} from '@ant-design/icons'
import Header from './Header'
import styles from './Base.module.less'

const { Sider } = Layout

const getMenus = () => {
    const menus = [
        {
            path: '/stats/realtime',
            name: '实时数据',
            icon: <AreaChartOutlined />
        },
        {
            path: '/stats/users',
            name: '用户数据',
            icon: <TeamOutlined />
        },
        {
            path: '/stats/knowledge',
            name: '卡片学习统计',
            icon: <FundOutlined />
        },
        {
            path: '/skill_feedback',
            name: '问题反馈',
            icon: <HeatMapOutlined />
        },
        {
            path: '/knowledge_feedback',
            name: '卡片反馈',
            icon: <ScheduleOutlined />
        },

        // {
        //     path: '/user_knowledge',
        //     name: '卡片自检记录',
        //     icon: <SafetyOutlined />,
        // },
        {
            path: '/tasks',
            name: '学习计划',
            icon: <CheckCircleOutlined />
        },
        {
            path: '/review_tasks',
            name: '复习计划',
            icon: <IssuesCloseOutlined />
        },
        {
            path: '/level_tasks',
            name: '关卡任务',
            icon: <ScheduleOutlined />
        },
        {
            path: '/stats/tasks',
            name: '任务统计',
            icon: <SafetyOutlined />
        },
        {
            path: '/user_questions',
            name: '能力自检记录',
            icon: <SafetyOutlined />
        },
        {
            path: '/users',
            name: '用户账号',
            icon: <UserOutlined />
        },
        {
            path: '/organizations',
            name: '机构管理',
            icon: <ClusterOutlined />
        },
        {
            path: '/sys_config',
            name: '系统配置',
            icon: <ClusterOutlined />
        },
        {
            path: '/sign-out',
            name: '退出',
            icon: <LogoutOutlined />
        }
    ]
    return menus
}


function Data(props) {
    const dispatch = useDispatch()
    const { pathname, search } = useLocation()


    const [selectedKey, setSelectedKey] = useState('')
    const initData = useCallback(async () => {
        const res = await api.subjects()
        if (res?.data) {
            dispatch(setSubjects(res.data))
        }
    }, [])
    useEffect(() => {
        const _menus = getMenus()
        // const selectedMenu = _menus.find(m => {
        //     const reg = /^\/([^\/]*).*$/
        //     return pathname.replace(reg, '$1') === m.path.replace(reg, '$1')
        // })
        let _selected = null
        let _match = 0
        const _arr2 = pathname.split('/')
        _menus.forEach((_menu) => {
            let _arr1 = _menu.path.split('/')
            if (_arr1[1] === _arr2[1] && _match === 0) {
                _selected = _menu
                _match = 1
            }
            if (_arr1[2] && _arr1[2] === _arr2[2]) {
                _selected = _menu
                _match = 2
            }
            if (_arr1[3] && _arr1[3] === _arr2[3]) {
                _selected = _menu
                _match = 3
            }
        })
        if (_selected) {
            setSelectedKey(_selected.path)
            document.title = _selected.name
        }
        initData()
    }, [pathname])


    const menuItems = useMemo(() => {
        const _menus = getMenus()
        return _menus.map((menu) => {
            const { path, name, icon } = menu
            return <Menu.Item key={path} icon={icon} title={name}>
                <Link to={path}>{name}</Link>
            </Menu.Item>
        })
    }, [])
    const handleCollapse = useCallback(() => {

    }, [])


    return (
        <div style={{ height: '100%' }}>
            <Header />
            <div className={styles.layout}>
                <Sider
                    // breakpoint="lg"
                    collapsible
                    defaultCollapsed={search.includes('collapsed=1')}
                    onCollapse={handleCollapse}
                    className={styles.sider}
                >
                    <Menu theme="dark" selectedKeys={selectedKey} mode="inline">
                        {menuItems}
                    </Menu>
                </Sider>
                <div className={styles.contentWrap}>
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default Data
